import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { MemberInfo } from '../getMember/types/memberTypes';

import { getAxiosRequestConfig } from '../utilities/http';

import a, {
  AddCaseActionTypes,
  ResetAddCase,
  SetAddCaseResponseInfo,
  SetCaseType,
  SetHomeNavigation,
  SetCaseMemberInfo,
  UpdateCaseMemberInfo,
  SetProviderDescriptionInfo,
  SetProviderInfo,
  SetProviderOption,
  SetIsDependentMember,
} from './types/addCaseActions';
import {
  AddCaseState,
  ProviderDescription,
  ProviderInfo,
  UploadAttachmentState,
} from './types/addCaseTypes';

import { DependentMemberInfo } from '../dependentMember/types/dependentMemberTypes';

export const addCase = (
  redirectTo: string,
  caseInfo: (MemberInfo & ProviderInfo) | (DependentMemberInfo & ProviderInfo),
  caseType: string,
  dispatch: Dispatch,
  attachments?: File | null,
  attachmentDetail?: UploadAttachmentState | null
): void => {
  let newCaseId: string;
  dispatch({
    type: a.ADD_CASE_PENDING,
  });
  axios
    .post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/createCase`,
      {
        ...caseInfo,
        recordType: caseType === 'Balance Bill' ? 'BalanceBill' : 'AccessIssue',
      },
      getAxiosRequestConfig()
    )
    .then((result: AxiosResponse<{ id: string }>) => {
      newCaseId = result.data.id;

      dispatch({
        type: a.ADD_CASE_FULFILLED,
        meta: { redirectTo },
        payload: { ...result, caseId: newCaseId },
      });

      setAddCaseResponseInfo('caseId', newCaseId);
      if (attachments != null && attachmentDetail != null && caseType === 'Balance Bill') {
        const formData = new FormData();
        formData.append('caseID', newCaseId);
        formData.append('contentLocation', attachmentDetail?.contentLocation);
        formData.append('title', newCaseId);
        formData.append('versionData', attachments, attachments.name);

        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL || ''}/api/case/uploadAttachment`,
            formData,
            getAxiosRequestConfig()
          )
          .then((result: AxiosResponse<{ success: boolean }>) => {
            dispatch({
              type: a.UPLOAD_ATTACHMENT,
              payload: { result },
            });
          })
          .catch(error => {
            if ((error as AxiosError).response) {
              dispatch({
                type: a.UPLOAD_ATTACHMENT_REJECTED,
                payload: error,
              });
            }
          });
      }
    })

    .catch(error => {
      if ((error as AxiosError).response) {
        dispatch({
          type: a.ADD_CASE_REJECTED,
          payload: error,
        });
      }
    });
};

export function createCaseInfo(
  redirectTo: string,
  caseInfo: (MemberInfo & ProviderInfo) | (DependentMemberInfo & ProviderInfo),
  caseType: string
): AddCaseActionTypes {
  if (caseInfo.scheduledDateOfService !== '1111-01-01T00:00:00Z') {
    const inputDate = new Date(caseInfo.scheduledDateOfService);
    const formattedDate = `${inputDate.toISOString().slice(0, 10)}T00:00:00Z`;
    /* eslint-disable no-param-reassign */
    caseInfo.scheduledDateOfService = formattedDate;
  }

  let recordType = 'AccessIssue';

  if (caseType === 'Balance Bill') {
    recordType = 'BalanceBill';
  } else if (caseType === 'Call Ahead') {
    recordType = 'CallAhead';
  }

  return {
    type: a.ADD_CASE,
    meta: { redirectTo },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/createCase`,
      {
        ...caseInfo,
        recordType,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function uploadAttachment(redirectTo: string, caseId: string): AddCaseActionTypes {
  return {
    type: a.UPLOAD_ATTACHMENT,
    meta: { redirectTo },
    payload: axios.post(
      `${process.env.REACT_APP_API_BASE_URL || ''}/api/case/uploadAttachment`,
      {
        title: 'testing-1',
        versionData: '',
        contentLocation: 'test',
        caseID: caseId,
      },
      getAxiosRequestConfig()
    ),
  };
}

export function setCaseType(caseType: string): SetCaseType {
  return {
    type: a.SET_CASE_TYPE,
    payload: caseType,
  };
}

export function setProviderOption(providerOption: boolean): SetProviderOption {
  return {
    type: a.SET_PROVIDER_OPTION,
    payload: providerOption,
  };
}

export function setAddCaseResponseInfo(
  key: keyof AddCaseState,
  value: AddCaseState[keyof AddCaseState]
): SetAddCaseResponseInfo {
  return {
    type: a.SET_ADD_CASE_RESPONE_INFO,
    meta: { key, value },
  };
}

export function resetAddCase(): ResetAddCase {
  return { type: a.RESET_ADD_CASE };
}

export function updateHomeNavigation(isHomeNavigation: boolean): SetHomeNavigation {
  return {
    type: a.SET_CASE_NAVIGATION,
    payload: isHomeNavigation,
  };
}

export function updateCaseMemberDetail(memberInfo: MemberInfo): UpdateCaseMemberInfo {
  return {
    type: a.UPDATE_CASE_MEMBER_INFO,
    payload: memberInfo,
  };
}

export function setIsDependentMember(isDependentMember: boolean): SetIsDependentMember {
  return {
    type: a.SET_IS_DEPENDENT_MEMBER,
    payload: isDependentMember,
  };
}

export function setCaseMemberInfo(
  key: keyof MemberInfo,
  value: MemberInfo[keyof MemberInfo]
): SetCaseMemberInfo {
  return {
    type: a.SET_CASE_MEMBER_INFO,
    meta: { key, value },
  };
}

export function setProviderInfo(
  key: keyof ProviderInfo,
  value: ProviderInfo[keyof ProviderInfo]
): SetProviderInfo {
  return {
    type: a.SET_PROVIDER_INFO,
    meta: { key, value },
  };
}

export function setProviderDescriptionInfo(
  key: keyof ProviderDescription,
  value: ProviderDescription[keyof ProviderDescription]
): SetProviderDescriptionInfo {
  return {
    type: a.SET_PROVIDER_DESCRIPTION_INFO,
    meta: { key, value },
  };
}
