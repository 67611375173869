import React, { ChangeEventHandler, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  AddCaseState,
  ProviderDescription,
  ProviderInfo,
} from '@apps/shared/src/addCase/types/addCaseTypes';
import {
  createCaseInfo,
  resetAddCase,
  setAddCaseResponseInfo,
  setProviderDescriptionInfo,
  setProviderInfo,
} from '@apps/shared/src/addCase/actions';
import { connect } from 'react-redux';
import { MemberInfo } from '@apps/shared/src/getMember/types/memberTypes';
import LoadingSpinner from '@apps/shared/src/components/LoadingSpinner';
import SuccessDialog from '../shared/successDialog';
import { RootState } from '../store';
import TextInput from '../shared/textInput';
import AppLayout from '../shared/AppLayout';

const useStyles = makeStyles({
  appContainer: {
    padding: '1rem',
    color: '#797C80',
    position: 'relative',
    height: 'calc(100vh - 120px - 2.5rem)',
  },
  arrowIcon: {
    height: '2rem',
    width: '2rem',
    cursor: 'pointer',
  },
  textBoxWrapper: {
    display: 'grid',
    gap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(30%, 1fr))',
  },
  formContainer: {
    color: '#797C80',
    height: 'calc(100vh - 20rem)',
    padding: '1.5rem 0',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '1px',
    },
  },
  radioWrapper: {
    padding: '1rem 0 .5rem 0',
    width: '50%',
  },
  radio: {
    display: 'flex',
    flexDirection: 'row',
  },
  checkWrapper: {
    padding: '1rem 0 .5rem 0',
    width: '70%',
  },
  check: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  checkBoxes: {
    display: 'flex',
  },
  checkBox: {
    margin: '0',
    paddin: '0 12px 0 0',
  },
  submitBtn: {
    gridColumn: '1 / -1',
    width: '45%',
    borderRadius: '.5rem',
    background: '#F97140',
    color: 'white',
    border: 'none',
    padding: '.8rem 1rem',
    margin: '0.5rem',
    cursor: 'pointer', // Set cursor to pointer
  },
  submitBtnDisabled: {
    gridColumn: '1 / -1',
    width: '45%',
    borderRadius: '.5rem',
    background: '#F97140',
    color: 'white',
    border: 'none',
    padding: '.8rem 1rem',
    margin: '0.5rem',
    cursor: 'not-allowed', // Set cursor to not-allowed when disabled
    opacity: 0.7, // Reduce opacity when disabled
  },
  buttonStyle: {
    width: '32%',
    position: 'absolute',
    bottom: 0,
    right: '1.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1.5rem',
  },
  backBtn: {
    gridColumn: '1 / -1',
    width: '50%',
    borderRadius: '.5rem',
    background: '#fff',
    color: '#888787',
    border: '1px solid #888787',
    padding: '.6rem 1rem',
    cursor: 'pointer',
    boxShadow: 'None',
  },
  successBtn: {
    gridColumn: '1 / -1',
    width: '50%',
    borderRadius: '.5rem',
    background: 'primary',
    color: 'white',
    fontWeight: 600,
    outline: 'None',
    padding: '.6rem',
    cursor: 'pointer',
    boxShadow: 'None',
  },
  accessIssueOption: {
    margintop: '1rem',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0)', // Initial transparent background
    zIndex: -1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0, // Initial opacity set to 0
    transition: 'background 0.3s ease, opacity 0.3s ease', // Transition properties
  },
  overlayVisible: {
    background: 'rgba(255, 255, 255, 0.8)', // Visible background color
    opacity: 1, // Visible opacity
    zIndex: 999,
  },
  formControl: {
    display: 'inline-flex',
    gap: '.8rem',
  },
});

type StateProps = {
  memberInfo: any;
  providerInfo: any;
  caseType: string;
  isCaseCreated: boolean;
  addingCase: boolean;
};

type DispatchProps = {
  createCaseInfo: (
    redirectTo: string,
    caseInfo: MemberInfo & ProviderInfo,
    caseType: string
  ) => void;
  setProviderInfo: (key: keyof ProviderInfo, value: ProviderInfo[keyof ProviderInfo]) => void;
  setProviderDescriptionInfo: (
    key: keyof ProviderDescription,
    value: ProviderDescription[keyof ProviderDescription]
  ) => void;
  setAddCaseResponseInfo: (
    key: keyof AddCaseState,
    value: AddCaseState[keyof AddCaseState]
  ) => void;
  resetAddCase: () => void;
};

const mapDispatchToProps = {
  setProviderInfo,
  setProviderDescriptionInfo,
  setAddCaseResponseInfo,
  resetAddCase,
  createCaseInfo,
};

const mapStateToProps = (state: RootState) => ({
  memberInfo: state.addCase.memberInfo,
  providerInfo: state.addCase.providerInfo,
  caseType: state.addCase.caseType,
  isCaseCreated: state.addCase.isCreated,
  addingCase: state.addCase.addingCase,
});

type Props = StateProps & DispatchProps;

const ProviderDetail: React.FC<Props> = ({
  memberInfo,
  providerInfo,
  caseType,
  setProviderInfo,
  setProviderDescriptionInfo,
  isCaseCreated,
  setAddCaseResponseInfo,
  resetAddCase,
  addingCase,
  createCaseInfo,
}: Props) => {
  const classes = useStyles();
  const [patientSeenFor, setPatientSeenFor] = React.useState<string>('');
  const [selectedAccessIssueReason, setSelectedAccessissueReason] = React.useState<string[]>([]);
  const [reasonForAccessIssueOthers, setReasonForAccessIssueOthers] = React.useState<string>('');
  const [isReasonForAccessIssueOthers, setIsReasonForAccessIssueOthers] =
    React.useState<boolean>(false);
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (caseType === '') {
      history.push(`/my-cases`);
    }
  }, []);

  const reasonForAccessIssue = [
    'Patient tried to make appointment, was denied',
    'Patient had appointment scheduled, Facility/Professional called to cancel',
    'Facility/Professional requires payment up front/self-pay',
    'Facility/Professional requires agreement/contract to see patient',
    'Other',
  ];

  const handleAccessissueCheckbox = (option: string) => () => {
    const updatedReasons: string[] = selectedAccessIssueReason.includes(option)
      ? selectedAccessIssueReason.filter(reason => reason !== option)
      : [...selectedAccessIssueReason, option];
    setSelectedAccessissueReason(updatedReasons);
  };

  const handleOtherAccessIssueReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForAccessIssueOthers(e.target.value);
  };

  const handleChange =
    (key: keyof ProviderInfo): ChangeEventHandler<HTMLInputElement> =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setProviderInfo(key, e.currentTarget.value);
    };

  const handleTimeChange =
    (field: keyof ProviderInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;

      // Convert 24-hour time to 12-hour format
      const [hours, minutes] = value.split(':');
      const hourNum = parseInt(hours, 10);
      const ampm = hourNum >= 12 ? 'PM' : 'AM';
      const formattedHours = hourNum % 12 || 12; // Convert 0 or 12 to 12, otherwise keep remainder
      value = `${formattedHours}:${minutes} ${ampm}`;

      setProviderInfo(field, value);
    };

  // Convert stored 12-hour format back to 24-hour format for input
  const convertTo24Hour = (time12: string) => {
    if (!time12) return '';
    const [time, modifier] = time12.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':');

    if (modifier === 'PM' && hours !== '12') {
      hours = String(parseInt(hours, 10) + 12);
    } else if (modifier === 'AM' && hours === '12') {
      hours = '00'; // Fix for 12 AM case
    } else if (modifier === 'AM') {
      hours = hours.padStart(2, '0'); // Ensure single-digit AM hours are formatted as "02"
    }

    return `${hours}:${minutes}`;
  };

  // const enableSubmitButton =
  const handleProviderDescriptionChange =
    (key: keyof ProviderDescription): ChangeEventHandler<HTMLInputElement> =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setProviderDescriptionInfo(key, e.target.value);
    };

  const handleSubmit = () => {
    const accessIssueReason = selectedAccessIssueReason.join(', ');
    /* eslint-disable no-param-reassign */
    providerInfo.reasonForAccessIssue = `${accessIssueReason}, ${reasonForAccessIssueOthers}`;
    createCaseInfo('', { ...memberInfo, ...providerInfo }, caseType);
  };

  const handleAccessIssueOther = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsReasonForAccessIssueOthers(e.target.checked);
  };

  const handleSuccess = (): void => {
    // reset the add case state
    resetAddCase();
    history.push('/my-cases');
  };

  const handlePatientSeenOption = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPatientSeenFor(e.target.value);

    if (e.target.value === 'general') {
      /* eslint-disable no-param-reassign */
      providerInfo.patientSeenFor = 'general';
    } else {
      /* eslint-disable no-param-reassign */
      providerInfo.patientSeenFor = '';
    }
  };

  const handleNumericInput = (e: React.ChangeEvent<HTMLInputElement>, length: number): void => {
    let inputValue = e.target.value.replace(/[^0-9]/g, '');
    inputValue = inputValue.slice(0, length);
    e.target.value = inputValue;
  };

  const validPhoneNumber = (phoneNumber: string): boolean => {
    if (phoneNumber !== '') {
      return phoneNumber.length === 10;
    }
    return true;
  };

  const validZipCode = (zipCode: string): boolean => {
    if (zipCode !== '') {
      return zipCode.length === 5;
    }
    return true;
  };

  const disableSubmit: boolean = (() => {
    const commonValidations =
      providerInfo.description.providerOfficeName === '' ||
      providerInfo.description.providerPhone === '' ||
      providerInfo.description.providerStreet === '' ||
      providerInfo.description.providerCity === '' ||
      providerInfo.description.providerState === '' ||
      providerInfo.description.providerZip === '' ||
      !validPhoneNumber(providerInfo.description?.providerPhone) ||
      !validZipCode(providerInfo.description?.providerZip);

    if (caseType === 'Access Issue') {
      return (
        providerInfo.typeOfAccessIssue === '' ||
        providerInfo.patientSeenFor === '' ||
        (selectedAccessIssueReason.length === 0 && reasonForAccessIssueOthers.length === 0) ||
        commonValidations
      );
    }

    if (caseType === 'Call Ahead') {
      return commonValidations;
    }

    return false;
  })();

  return (
    <AppLayout>
      <div className={`${classes.overlay} ${addingCase ? classes.overlayVisible : ''}`}>
        <LoadingSpinner />
      </div>
      <div className={classes.appContainer}>
        <ArrowBackIcon className={classes.arrowIcon} onClick={handleBack} />
        <Typography>
          {`** Please do not submit ${caseType.toLowerCase()}s from multiple providers on the same submission.`}
        </Typography>
        <div className={classes.formContainer}>
          <div className={classes.textBoxWrapper}>
            <TextInput
              label="Provider Office Name*"
              value={providerInfo.description?.providerOfficeName || ''}
              onChangeEvent={handleProviderDescriptionChange('providerOfficeName')}
              required
            />

            {caseType === 'Call Ahead' && (
              <TextInput
                label="Phone*"
                value={providerInfo.description?.providerPhone || ''}
                onChangeEvent={handleProviderDescriptionChange('providerPhone')}
                validators={['validatePhoneNumberLength']}
                errorMessages={['Must be a valid phone number']}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onInput: e => handleNumericInput(e, 10),
                }}
                required
              />
            )}

            {caseType === 'Access Issue' && (
              <TextInput
                label="Street*"
                value={providerInfo.description?.providerStreet || ''}
                onChangeEvent={handleProviderDescriptionChange('providerStreet')}
                required
              />
            )}
          </div>

          {caseType === 'Call Ahead' && (
            <div>
              <TextInput
                label="Street*"
                value={providerInfo.description?.providerStreet || ''}
                onChangeEvent={handleProviderDescriptionChange('providerStreet')}
                required
              />
            </div>
          )}

          <div className={classes.textBoxWrapper}>
            <TextInput
              label="City*"
              value={providerInfo.description?.providerCity || ''}
              onChangeEvent={handleProviderDescriptionChange('providerCity')}
              required
            />

            <TextInput
              label="State*"
              value={providerInfo.description?.providerState || ''}
              onChangeEvent={handleProviderDescriptionChange('providerState')}
              required
            />

            <TextInput
              label="Zip Code*"
              value={providerInfo.description?.providerZip || ''}
              onChangeEvent={handleProviderDescriptionChange('providerZip')}
              validators={['validateZipCode']}
              errorMessages={['Must be a valid Zip Code']}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
                maxLength: 5,
                onInput: e => handleNumericInput(e, 5),
              }}
              required
            />

            {caseType === 'Call Ahead' && (
              <>
                <TextInput
                  type="date"
                  name="Appt_Scheduled__c"
                  label="If Scheduled, Appt date"
                  placeholder="mm/dd/yyyy"
                  onChangeEvent={handleChange('scheduledDateOfService')}
                  value={providerInfo.scheduledDateOfService}
                />

                <TextInput
                  type="time"
                  name="Appt_Time__c"
                  label="Appointment Time"
                  placeholder="HH:MM"
                  onChangeEvent={handleTimeChange('timeOfAppointment')}
                  value={convertTo24Hour(providerInfo.timeOfAppointment)}
                />
              </>
            )}

            {caseType === 'Access Issue' && (
              <TextInput
                label="Phone*"
                value={providerInfo.description?.providerPhone || ''}
                onChangeEvent={handleProviderDescriptionChange('providerPhone')}
                validators={['validatePhoneNumberLength']}
                errorMessages={['Must be a valid phone number']}
                inputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  onInput: e => handleNumericInput(e, 10),
                }}
                required
              />
            )}

            {caseType === 'Access Issue' && (
              <>
                <TextInput
                  label="If Scheduled, date of service"
                  placeholder="mm/dd/yyyy"
                  value={providerInfo.scheduledDateOfService}
                  onChangeEvent={handleChange('scheduledDateOfService')}
                  type="date"
                />

                {/* <TextInput label="If scheduled, date of service" value="" /> */}
                <TextInput
                  label="Additional Comments (if any)"
                  value={providerInfo.additionalComment}
                  onChangeEvent={handleChange('additionalComment')}
                />
              </>
            )}
          </div>
          {caseType === 'Access Issue' && (
            <>
              <div className={classes.radioWrapper}>
                <Typography>Type of Access Issue?*</Typography>
                <FormControl>
                  <RadioGroup
                    value={providerInfo.typeOfAccessIssue}
                    onChange={handleChange('typeOfAccessIssue')}
                    className={classes.radio}
                  >
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      value="Facility"
                      label="Provider (Hospital)"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      value="Physician"
                      label="Professional (Physician)"
                    />
                    <FormControlLabel
                      control={<Radio color="primary" />}
                      value="Professional - Physician Unknown"
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className={classes.radioWrapper}>
                <Typography>
                  What is the patient being seen for?* (please include as much detail as possible)
                </Typography>
                <FormControl>
                  <RadioGroup
                    value={patientSeenFor}
                    onChange={handlePatientSeenOption}
                    className={classes.radio}
                  >
                    <FormControlLabel
                      value="general"
                      control={<Radio color="primary" />}
                      label="General Care"
                      checked={patientSeenFor === 'general'}
                    />
                    <FormControlLabel
                      value="specificReason"
                      control={<Radio color="primary" />}
                      label="Specific reason"
                      checked={patientSeenFor === 'specificReason'}
                    />
                  </RadioGroup>
                </FormControl>
                {patientSeenFor === 'specificReason' && (
                  <TextInput
                    placeholder="Type of surgery, diagnosis etc...*"
                    value={providerInfo.patientSeenFor}
                    onChangeEvent={handleChange('patientSeenFor')}
                    multiline
                    minRow={4}
                    maxRow={4}
                  />
                )}
              </div>
              <div className={classes.checkWrapper}>
                <Typography>Reason for Access Issue (select all that apply)*</Typography>
                <FormControl className={`${classes.formControl} ${classes.check}`}>
                  {reasonForAccessIssue.map((option, index) => (
                    <div key={option} className={classes.checkBoxes}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={
                              option === 'Other'
                                ? handleAccessIssueOther
                                : handleAccessissueCheckbox(option)
                            }
                            color="primary"
                          />
                        }
                        value={option}
                        label={option}
                        labelPlacement="end"
                      />
                      {/* <Checkbox
              className={classes.checkBox}
              value={option}
              onChange={option==="" ? handleAccessissueCheckbox(option) : handleAccessIssueOther}
            />
            <Typography className={classes.accessIssueOption}>{option}</Typography> */}
                    </div>
                  ))}
                </FormControl>
                {isReasonForAccessIssueOthers && (
                  <TextInput
                    placeholder="Facility was not there to attend.*"
                    onChangeEvent={handleOtherAccessIssueReasonChange}
                    value={reasonForAccessIssueOthers}
                    multiline
                    minRow={4}
                    maxRow={4}
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className={classes.buttonStyle}>
          <Button
            variant="outlined"
            color="default"
            onClick={handleBack}
            className={classes.backBtn}
          >
            BACK
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
            className={classes.successBtn}
          >
            SUBMIT
          </Button>
        </div>

        {isCaseCreated && (
          <SuccessDialog
            icon="images/success.svg"
            open={isCaseCreated}
            title="Submitted"
            message="Thanks for submitting the case form. We will review your case and get back to you soon. New cases will appear within 1 business day."
            buttonText="Continue"
            handleSuccess={handleSuccess}
            handleCloseDialog={() => setAddCaseResponseInfo('isCreated', false)}
          />
        )}
      </div>
    </AppLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetail);
