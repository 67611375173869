import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import {
  makeStyles,
  createStyles,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  Radio,
  Typography,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { setCaseType, setProviderOption } from '@apps/shared/src/addCase/actions';

import { RootState } from '../store';
import PrimaryButton from '../shared/primaryButton';

const useStyles = makeStyles(() =>
  createStyles({
    Modal: {
      height: 'calc(100vh - 70px - 50px)',
      width: '40%',
      backgroundColor: '#fff',
      marginTop: '70px',
      marginLeft: '30%',
      borderRadius: '.5rem',
    },
    smallScreenModal: {
      height: 'calc(100vh - 5.6rem)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      backgroundColor: '#fff',
      padding: '0 1.5rem',
      borderRadius: '.5rem',
      position: 'relative',
      height: '100%',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        width: '1px',
      },
    },
    box: {
      flex: 1,
      backgroundColor: '#ffffff',
    },
    header: {
      position: 'absolute',
      top: '10px',
      right: '10px',
    },
    imgWrapper: {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundImage: 'url("./images/typeOfIssue.svg")',
      backgroundSize: 'auto calc(100% - 15px)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    closeBtn: {
      color: '#36383D',
      fontWeight: 600,
      cursor: 'pointer',
    },
    headingTxt: {
      color: '#36383D',
      fontWeight: 600,
      fontSize: 'clamp(1rem, 0.1rem + 1.875vw, 1.3rem)',
      margin: '0.5rem 0',
    },
    headingTxtSmall: {
      fontSize: '1rem',
      color: '#36383D',
      fontWeight: 600,
      margin: '0.5rem 0',
    },
    helperText: {
      marginBottom: '1rem',
      color: '#888787',
      fontSize: 'clamp(0.75rem, 0rem + 1.5625vw, 1rem)',
      fontweight: 500,
    },
    helperTextSmall: {
      fontSize: '0.75rem',
      marginBottom: '1rem',
      color: '#888787',
      fontweight: 500,
    },
    checkBox: {
      display: 'flex',
      alignItems: 'start',
      marginBottom: '.5rem',
    },
    checkBoxText: {
      color: '#797C80',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem, -0.4rem + 1.875vw, 0.8rem)',
    },
    checkBoxTextSmall: {
      color: '#797C80',
      fontWeight: 400,
      fontSize: '0.65rem',
    },
    footer: {
      marginTop: '.5rem',
      marginBottom: '1rem',
      backgroundColor: '#fff',
    },
  })
);

type DispatchProps = {
  setCaseType: (caseType: string) => void;
  setProviderOption: (providerOption: boolean) => void;
};

const mapDispatchToProps = {
  setCaseType,
  setProviderOption,
};

const mapStateToProps = (state: RootState) => ({
  caseType: state.addCase.caseType,
  providerOption: state.addCase.providerOption,
});

type ParentProps = {
  caseSubmissionModalOpen: boolean;
  setCaseSubmissionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type StateProps = {
  caseType: string;
  providerOption: boolean;
};

type Props = StateProps & ParentProps & DispatchProps;

const TypeSelectionModal = ({
  caseType,
  providerOption,
  caseSubmissionModalOpen,
  setCaseSubmissionModalOpen,
  setCaseType,
  setProviderOption,
}: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const [issue, setIssue] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const isSmallScreen = useMediaQuery('(max-height:650px)');

  const handleClose = () => {
    setCaseSubmissionModalOpen(false);
  };

  const handleIssueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIssue(e.target.value);
    setCaseType(e.target.value);
  };

  // const handleProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setProviderOption(e.target.value === 'Yes');
  //   setIsProvidedOptionSelected(true);
  // };

  const handleCheckboxChange = () => {
    setIsTermsAccepted(!isTermsAccepted);
  };

  const handleSubmit = () => {
    if (caseType === 'Access Issue') {
      history.push(`/member-detail?param=${caseType}`);
    } else {
      history.push(`/member-detail?param=${caseType}`);
    }
  };

  useEffect(() => {
    setIssue('Balance Bill');
    setCaseType('Balance Bill');
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal
        className={classes.Modal}
        open={caseSubmissionModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.container}>
          <div className={classes.box}>
            <div className={classes.header}>
              <CloseIcon onClick={handleClose} className={classes.closeBtn} />
            </div>
            <div className={classes.imgWrapper} />
          </div>
          <div className={classes.box}>
            <div>
              <Typography
                className={`${isSmallScreen ? classes.headingTxtSmall : classes.headingTxt}`}
              >
                TYPE OF CASE
              </Typography>
              <FormControl>
                <RadioGroup row onChange={handleIssueChange} defaultValue="Balance Bill">
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="Balance Bill"
                    label="Balance Bill"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="Access Issue"
                    label="Access Issue"
                  />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    value="Call Ahead"
                    label="Call Ahead"
                  />
                </RadioGroup>
              </FormControl>
              {issue === 'Balance Bill' && (
                <Typography
                  variant="body2"
                  className={`${isSmallScreen ? classes.helperTextSmall : classes.helperText}`}
                >
                  A balance Bill occurs when a provider believes that additional funds are owed in
                  addition to reimbursement already provided by the health plan and the amount of
                  your patient responsibility (e.g. copay, coinsurance, deductible).
                </Typography>
              )}
              {issue === 'Access Issue' && (
                <Typography
                  variant="body2"
                  className={`${isSmallScreen ? classes.helperTextSmall : classes.helperText}`}
                >
                  **An Access Issue occurs when a provider does not accept your health plan
                  coverage, resulting in your appointment being denied or demanding payment in full
                  prior to the appointment.
                </Typography>
              )}
              {issue === 'Call Ahead' && (
                <Typography
                  variant="body2"
                  className={`${isSmallScreen ? classes.helperTextSmall : classes.helperText}`}
                >
                  Call ahead is designed for those actively seeking to make an appointment with a
                  physician you are seeing for the first time on this new health plan. You should
                  have either:
                  <div>a&#41; A previously scheduled appointment within the next 30 days</div>
                  <div>
                    b&#41; The need to call in the next two weeks to schedule a new appointment
                  </div>
                </Typography>
              )}
            </div>
            <div className={classes.footer}>
              {issue === 'Balance Bill' && (
                <div className={classes.checkBox}>
                  <Checkbox
                    color="primary"
                    checked={isTermsAccepted}
                    onChange={handleCheckboxChange}
                  />
                  <Typography
                    className={`${
                      isSmallScreen ? classes.checkBoxTextSmall : classes.checkBoxText
                    }`}
                  >
                    I have compared the Explanation of Benefits from my TPA with the provider
                    statement, and the provider is demanding more than my patient responsibility.”
                  </Typography>
                </div>
              )}

              <PrimaryButton
                type="button"
                title="ADD CASE"
                onClick={handleSubmit}
                disabled={(!isTermsAccepted && caseType === 'Balance Bill') || caseType === ''}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TypeSelectionModal);
